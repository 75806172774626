//header数据
const headerData = {
  home: "Home",
  services: "Services",
  about: "About",
  contact: "Contact",
};

//footer数据
const footerData = {
  companyName: "Shanghai Lead Speed International Logistics Co.,LTD.",
  copyright: "©2022 版权所有 All Rights Reserved",
  ICP: [
    "沪公网安备",
    "31011502008894",
    "号",
    "ICP备案编号：沪ICP备",
    "14043075号-33",
  ],
  contactProblem: "Need Assistance Fast?",
  contactTitle: "Contact US",
};

//home数据
const homeData = {
  title: "A well-known freight forwarder",
  companyName: "Shanghai Lead Speed International Logistics Co.,LTD.",
  aboutTitle: "About US",
  companyIntro:
    "is a professional, dynamically driven forwarding Company locate in Shanghai,China.",
  companyInfo:
    "We have grown to become a significant force among independent freight forwarders and our goal is to provide competitive shipping and air services from all main Chinese ports to all over the world. We tailor to the needs of our customers while maintaining one of the highest levels of customer service in the industry. We want to make every aspect of shipping your cargo as easy as possible.",
  btnMore: "LEARN MORE",
  coreTitle: "Core Business",
  text1: "Ocean Freight Service",
  text2: "Air Freight Service",
  text3: "Warehouse storage&Distribution",
  text4: "Trucking",
  text5: "Custom Brokerage",
  contactTitle: "Contact US",
  phone: "Phone：",
  phoneNum: "008618021052066",
  email: "Email：",
  emailValue: "sales01@leadspeedintl.com",
};

//services数据
const servicesData = {
  title: "Our Services",
  servicesIntro: [
    "We tailor to the needs of our ",
    "customers ",
    "while maintaining one of the highest levels of customer service in the industry.",
  ],
  airTitle: "Air Freight Service",
  airInfo:
    "With our expertise,uncompromising service, and competitive rates, LeadSpeed intl provides in-depth know-how as well as a broad spectrum of flexible and reliable services for air freight transports. Whether you need express air assistance, door to door service or most any type of accommodation request, we can arrange the best shipping options to meet your specific needs for safety, time, and convenience.",
  oceanTitle: "Ocean Freight Service",
  oceanInfo:
    "With decades of experience，Lead Speed intl offers a superior means of transporting your goods by sea. Through an extensive network of connections with sea carriers we are able to deliver a fast, cost-efficient and reliable ocean freight services.",
  OtherTitle: "Other service",
  text1: "Railway",
  text2: "Trucking",
  text3: "Custom Brokerage",
  text4: "Warehouse storage&Distribution",
  text5: "Stuffing&Lashing",
  text6: "Insurance",
};

//about数据
const aboutData = {
  title: "About LeadSpeed",
  companyName: "Shanghai Lead Speed International Logistics Co.,LTD.",
  companyIntro:
    "is a professional, dynamically driven forwarding Company locate in Shanghai,China.",
  aboutInfo:
    "We have grown to become a significant force among independent freight forwarders and our goal is to provide competitive shipping and air services from all main Chinese ports to all over the world. We tailor to the needs of our customers while maintaining one of the highest levels of customer service in the industry. We want to make every aspect of shipping your cargo as easy as possible.",
  email: "Email",
};

//about数据
const contactData = {
  title: "Contact US",
  phone: "Phone：",
  phoneNum: "008618021052066",
  email: "Email：",
  emailValue: "sales01@leadspeedintl.com",
};

export default {
  headerData,
  footerData,
  homeData,
  servicesData,
  aboutData,
  contactData,
};
