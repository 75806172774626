<template>
  <div class="footer pr ptb-100">
    <div class="footer-container df jc-sb">
      <div class="left">
        <p class="fw-b">Shanghai Lead Speed International Logistics Co.,LTD.</p>
        <div class="mt-25">
          <span style="display: block">©2022 版权所有 All Rights Reserved</span>
          <p class="df">
            <span>
              <img
                src="../assets/img/contact/bata.png"
                alt=""
                width="16"
              />沪公网安备
              <a
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502008894"
                target="_blank"
                >31011502008894</a
              >号
            </span>
            <span>
              ICP备案编号：沪ICP备
              <a
                href="https://beian.miit.gov.cn/#/Integrated/index"
                target="_blank"
                class="color-black"
                >14043075号-33</a
              >
            </span>
          </p>
        </div>
      </div>
      <div class="right">
        <p>Need Assistance Fast?</p>
        <el-button class="mt-20">
          <router-link
            class="nav-link custom-nav-link color-white"
            :to="{ name: 'Contact' }"
          >
            Contact US
          </router-link></el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>
