//header数据
const headerData = {
  home: "首页",
  services: "服务",
  about: "关于",
  contact: "联系",
};

export default {
  headerData,
};
